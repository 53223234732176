/*
 * @Author: Matthew Auld 
 * @Date: 2018-12-21 17:49:54 
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-27 15:13:19
 * @File: LoginPage.css
 * Copyright 2018 JumpButton North - All rights reserved. 
 */

#login-page{
	height:100%;
	background:#C7D6D8 url(/img/login-background.jpg) center center no-repeat;
	background-size:cover;
}

#login-page #login-ux{
	background:rgba(0,0,0,0.5);
	border:1px #000 solid;
	border-radius:3px;
	padding:.25rem .5rem .5rem .5rem;
	color:#FFF;
	width:35vw;
	max-width:100%;
}

#login-page #login-ux .alternate-logins a{
	font-size:18pt;
	border-radius:4px;
	background:#CECECE;
}

#login-page #login-ux .alternate-logins a.bg-fb{
	background:#3B5998;
}

#login-page #login-ux .alternate-logins a.bg-tw{
	background:#00ACED;
}

#login-page #login-ux .alternate-logins a.bg-li{
	background:#007BB6;
}

#login-page #login-ux .alternate-logins a.bg-gp{
	background:#DD4B39;
}

#login-wrapper{
	display:flex;
	justify-content:center;
	align-items:center;
	height:100%;
}

@media screen and (max-width:767px){
	#login-page #login-ux{
		width:100%;
	}

	#login-wrapper{
		height:auto;
		display:block;
		margin-bottom:.5rem;
	}
}