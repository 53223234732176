#top-bar{
	background-color:#FF7400;
}

.content{
	display:flex;
	flex:1 1 100%;
	overflow-y:auto;
}

#footer{
	width:100%;
	background:#101010;
	color:#FFF;
	font-size:12px;
	color:#636B6F;
}

#footer .col-4{
	display:flex;
	align-items:center;
}

#footer .footer-links{
	justify-content:flex-start;
}

#footer .footer-links > a,#footer .media-links a{
	color:#CECECE;
}

#footer .footer-links > a:hover{
	color:#FFF;
	text-decoration:none;
}

#footer .footer-links > a:after{
	content: " | ";
	display:inline-block;
	margin-left:5px;
	margin-right:5px;
	color:#636B6F;
}

#footer .footer-links > a:last-child:after{
	display:none;
}

#footer .media-links{
	display:flex;
	align-items:center;
	justify-content:flex-end;
}

#footer .media-links a{
	margin-left:5px;
	margin-right:5px;
	font-size:18px;
}

#footer .media-links a:last-child{
	margin-right:0;
}

#footer .media-links a.twitter:hover{
	color:#38A1F3;
}

#footer .media-links a.facebook:hover{
	color:#3B5998;
}

#footer .media-links a.instagram:hover{
	color:#8A3AB9;
}

#footer .media-links a.github:hover{
	color:#6CC644;
}

#footer .media-links a.linkedin:hover{
	color:#0077B5;
}

#footer .media-links a.tumblr:hover{
	color:#34526f;
}

@media screen and (max-width:767px){
	.navbar{
		min-height:50px;
		flex:none;
	}

	.content{
		padding-top:.5rem;
		padding-bottom:.5rem;
		flex:1 1 100%;
	}

	#footer{
		text-align:center;
	}

	#footer .footer-links,#footer .media-links{
		justify-content:center;
	}
}