html,body{
	height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,#application{
	height:100%;
	width:100%;
	display:flex;
}

#application{
	flex-direction:column;
}

.btn-pp{
	background:#FF7400;
	color:#FFF;
}
#top-bar{
	background-color:#FF7400;
}

.content{
	display:flex;
	flex:1 1 100%;
	overflow-y:auto;
}

#footer{
	width:100%;
	background:#101010;
	color:#FFF;
	font-size:12px;
	color:#636B6F;
}

#footer .col-4{
	display:flex;
	align-items:center;
}

#footer .footer-links{
	justify-content:flex-start;
}

#footer .footer-links > a,#footer .media-links a{
	color:#CECECE;
}

#footer .footer-links > a:hover{
	color:#FFF;
	text-decoration:none;
}

#footer .footer-links > a:after{
	content: " | ";
	display:inline-block;
	margin-left:5px;
	margin-right:5px;
	color:#636B6F;
}

#footer .footer-links > a:last-child:after{
	display:none;
}

#footer .media-links{
	display:flex;
	align-items:center;
	justify-content:flex-end;
}

#footer .media-links a{
	margin-left:5px;
	margin-right:5px;
	font-size:18px;
}

#footer .media-links a:last-child{
	margin-right:0;
}

#footer .media-links a.twitter:hover{
	color:#38A1F3;
}

#footer .media-links a.facebook:hover{
	color:#3B5998;
}

#footer .media-links a.instagram:hover{
	color:#8A3AB9;
}

#footer .media-links a.github:hover{
	color:#6CC644;
}

#footer .media-links a.linkedin:hover{
	color:#0077B5;
}

#footer .media-links a.tumblr:hover{
	color:#34526f;
}

@media screen and (max-width:767px){
	.navbar{
		min-height:50px;
		flex:none;
	}

	.content{
		padding-top:.5rem;
		padding-bottom:.5rem;
		flex:1 1 100%;
	}

	#footer{
		text-align:center;
	}

	#footer .footer-links,#footer .media-links{
		justify-content:center;
	}
}
/*
 * @Author: Matthew Auld 
 * @Date: 2018-12-19 15:23:09 
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-19 15:34:53
 * @File: LandingPage.css
 * Copyright 2018 JumpButton North - All rights reserved. 
 */

#landing-page{
	background:#000 url(/img/background.jpg) center center no-repeat;
	background-size:cover;
}
/*
 * @Author: Matthew Auld 
 * @Date: 2018-12-21 17:49:54 
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-27 15:13:19
 * @File: LoginPage.css
 * Copyright 2018 JumpButton North - All rights reserved. 
 */

#login-page{
	height:100%;
	background:#C7D6D8 url(/img/login-background.jpg) center center no-repeat;
	background-size:cover;
}

#login-page #login-ux{
	background:rgba(0,0,0,0.5);
	border:1px #000 solid;
	border-radius:3px;
	padding:.25rem .5rem .5rem .5rem;
	color:#FFF;
	width:35vw;
	max-width:100%;
}

#login-page #login-ux .alternate-logins a{
	font-size:18pt;
	border-radius:4px;
	background:#CECECE;
}

#login-page #login-ux .alternate-logins a.bg-fb{
	background:#3B5998;
}

#login-page #login-ux .alternate-logins a.bg-tw{
	background:#00ACED;
}

#login-page #login-ux .alternate-logins a.bg-li{
	background:#007BB6;
}

#login-page #login-ux .alternate-logins a.bg-gp{
	background:#DD4B39;
}

#login-wrapper{
	display:flex;
	justify-content:center;
	align-items:center;
	height:100%;
}

@media screen and (max-width:767px){
	#login-page #login-ux{
		width:100%;
	}

	#login-wrapper{
		height:auto;
		display:block;
		margin-bottom:.5rem;
	}
}
/*
 * @Author: Matthew Auld 
 * @Date: 2018-12-27 18:49:56 
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-27 19:02:17
 * @File: RegistrationPage.css
 * Copyright 2018 JumpButton North - All rights reserved. 
 */

 #register-page{
	height:100%;
	background:#C7D6D8 url(/img/register-background.jpg) center center no-repeat;
	background-size:cover;
}

#register-page #register-ux{
	background:rgba(0,0,0,0.5);
	border:1px #000 solid;
	border-radius:3px;
	padding:.25rem .5rem .5rem .5rem;
	color:#FFF;
	width:35vw;
	max-width:100%;
}

#register-page #register-ux .alternate-logins a{
	font-size:18pt;
	border-radius:4px;
	background:#CECECE;
}

#register-page #register-ux .alternate-logins a.bg-fb{
	background:#3B5998;
}

#register-page #register-ux .alternate-logins a.bg-tw{
	background:#00ACED;
}

#register-page #register-ux .alternate-logins a.bg-li{
	background:#007BB6;
}

#register-page #register-ux .alternate-logins a.bg-gp{
	background:#DD4B39;
}

#register-wrapper{
	display:flex;
	justify-content:center;
	align-items:center;
	padding-top:.5rem;
	padding-bottom:.5rem;
}

@media screen and (max-width:767px){
	#register-page #register-ux{
		width:100%;
	}

	#register-wrapper{
		height:auto;
		display:block;
		margin-bottom:.5rem;
	}
}
