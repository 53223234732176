/*
 * @Author: Matthew Auld 
 * @Date: 2018-12-19 15:23:09 
 * @Last Modified by: Matthew Auld
 * @Last Modified time: 2018-12-19 15:34:53
 * @File: LandingPage.css
 * Copyright 2018 JumpButton North - All rights reserved. 
 */

#landing-page{
	background:#000 url(/img/background.jpg) center center no-repeat;
	background-size:cover;
}