html,body{
	height:100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,#application{
	height:100%;
	width:100%;
	display:flex;
}

#application{
	flex-direction:column;
}

.btn-pp{
	background:#FF7400;
	color:#FFF;
}